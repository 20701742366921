@use 'common'
@use "sass:math"

.container
	display: flex
	position: sticky
	top: 0
	z-index: 2
	height: fit-content

.wrapper
	display: flex
	flex-direction: column
	justify-content: flex-end
	position: absolute
	right: 0
	top: 0
	background-color: #fff
	z-index: 2
	position: sticky
	top: 0
	margin-left: auto
	width: fit-content
	position: absolute
	top: 1rem
	right: 1rem
	border-radius: 2.5rem
	overflow: hidden
	@media (min-width: 768px)
		top: 5.375rem
		right: 3.9375rem

.wrapperIsOpen
	// padding: 0 2rem 2rem
	box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.125)
	border-radius: 5px
	background-color: #F8F8F8
	padding: 22px
	top: 0
	right: 0
	width: 100%
	@media (min-width: 768px)
		top: 3.75rem
		right: 3.5rem
		width: auto

.linkWrapper
	margin-bottom: 21px

.link
	+common.button
	&:hover
		background-color: #000
		color: #fff

.linkText
	font-size: 18px
	font-weight: 400
	margin-top: .375rem

.menuOpen,
.menuClose
	display: none

.menuBtn
	background-color: transparent
	color: inherit
	border: transparent
	margin-left: auto
	cursor: pointer
	padding: 0

.moreBtn
	font-family: switzer, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif
	font-size: 1.5rem
	border-radius: 2.5rem
	line-height: 1
	border: 1px solid #000
	padding: .5rem .625rem
	font-weight: 500

.closeBtn
	position: absolute
	right: 22px
	top: 22px

.links
	display: none
	&.isOpen
		display: flex
		flex-direction: column
		padding-right: 6rem
