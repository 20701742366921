@use 'common'

.Block
	padding: 1rem 0
	&:nth-child(1)
		padding-top: 3rem

	&:nth-last-child(1)
		padding-bottom: 3rem

.In
	margin: auto

	:global(.blocksToLeft) &
		margin: inherit

	h1
		font-size: 45px
		line-height: 45px
		text-align: center

	h2
		margin-top: 2rem

	ul
		margin-top: -2rem
		padding-left: 1.6rem
		li
			margin: 1rem 1rem 1rem 0
			&::marker
				color: #9d9a9c
				font-size: 2rem
			&:nth-child(1)
				margin-top: 1.5rem

	p
		margin: 0
		font-size: 18px
		line-height: 1.4
		letter-spacing: 0.25px
		@media (min-width: 768px)
			font-size: 20px
			line-height: 35px
		b
			font-weight: 800
		i
			font-weight: 300

	a
		text-decoration: underline
		&:hover
			text-decoration: none

	ol
		li
			font-size: 20px
			line-height: 35px

.AlignLeft
	.In
		max-width: 600px
		margin: 0
		@media (min-width: 768px)
			margin: 0 3rem
		@media (min-width: 1200px)
			margin: 0 5rem
			max-width: 700px
		@media (min-width: 1440px)
			max-width: 768px

.ScrollTarget
	padding-top: 150px
	margin-top: -150px
