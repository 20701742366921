@use 'common'
@use 'sass:math'

.TwitterEmbed
	display: flex
	justify-content: center
	padding: 3rem 0

.Embed
	width: 100%
