@use 'common'
@use 'sass:math'

.Cover
	margin-bottom: 2rem
	@media (min-width: 768px)
		height: 60vh
		margin-bottom: 0

.Title
	display: flex
	font-size: 48px
	line-height: 63px
	margin: 0
	margin-bottom: 20px
	@media (min-width: 768px)
		font-size: 72px
		line-height: 95px
		margin-bottom: 39px

.Email
	+common.button
	display: flex
