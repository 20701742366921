@use 'common'
@use "sass:math"

.HomePage
	display: flex
	flex-direction: column
	margin: 90px 25px
	@media (min-width: 768px)
		margin: 60px 68px

.Projects
	display: flex
	flex-wrap: wrap
