@use 'common'
@use "sass:math"

.Article
	display: flex
	flex-direction: column
	margin: 90px 25px
	border-bottom: 1px solid #000
	max-width: 768px
	@media (min-width: 768px)
		margin: 60px 68px 11.25rem

.Title
	font-size: 48px
	line-height: 63px
	margin: 0
	margin-bottom: 20px
	font-weight: 400
	@media (min-width: 768px)
		font-size: 72px
		line-height: 95px
		margin-bottom: 39px

.Date
	display: flex
	width: fit-content
	border: 1px solid #000
	border-radius: 20px
	padding: 0.25rem 0.75rem
	font-size: 20px
	background-color: #fff
	@media (min-width: 768px)
		font-size: 25px

.Html
	margin: 2rem 0
	font-size: 18px
	line-height: 1.4
	letter-spacing: 0.25px
	@media (min-width: 768px)
		font-size: 20px
		line-height: 35px

	a
		text-decoration: underline

	img
		margin: 1rem 0
		width: 100%
		object-fit: contain
		height: auto

	figure
		margin: 0

	h1
		font-size: 45px
		line-height: 45px
		text-align: center

	h2
		margin-top: 2rem
		font-size: 1.5em
