@use '../vars'

=button
	width: fit-content
	border: 1px solid #000
	border-radius: 20px
	padding: 0.25rem 0.75rem
	font-size: 25px
	line-height: 33px
	background-color: #fff

