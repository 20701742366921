@use 'common'
@use "sass:math"

@keyframes logo-scale
	0%
		transform: scale(1) translate3d(0,0,0)
	25%
		transform: scale(1.2) translate3d(0,0,0)
	50%
		transform: scale(1) translate3d(0,0,0)
	75%
		transform: scale(1.2) translate3d(0,0,0)
	100%
		transform: scale(1.0) translate3d(0,0,0)

=popup
	.Project
		width: 100%

	.InfoBox
		display: flex
		flex-direction: column
		justify-content: flex-end
		padding: 1.5rem
		border-radius: 10px
		position: absolute
		bottom: 0
		left: 0
		right: 0
		min-height: 250px
		max-width: 350px

	.Name
		background-color: transparent
		border-color: #000

	.LeadParagraph
		margin-bottom: 1rem

	.WhiteText
		border-color: #fff

.Project
	display: flex
	flex-direction: column
	position: relative
	width: math.div(100%, 2)
	padding: 1.5rem
	margin-bottom: 30px
	@media (min-width: 768px)
		width: math.div(100%, 3)
		margin-bottom: 100px
	@media (min-width: 992px)
		width: math.div(100%, 4)
	&:nth-child(1),
	&:nth-child(2)
		.Logo
			@media (max-width: 767.9px)
				animation: logo-scale 5s
				animation-iteration-count: 1

	@media (max-width: 767.9px)
		&.isOpen
			+popup
			.InfoBox
				width: auto
				position: fixed
				top: 0
				margin: 1rem
				z-index: 2
				justify-content: flex-start

			.Close
				+common.button
				display: flex
				position: absolute
				right: 10px
				top: 10px
				z-index: 2
				color: #000

			.Image
				flex-grow: 1

	@media (min-width: 768px)
		&:hover
			+popup

.Logo,
.Image
	position: relative

.Logo
	width: 85px
	height: 85px
	border-radius: 50%
	overflow: hidden
	@media (min-width: 375px)
		width: 100px
		height: 100px
	@media (min-width: 768px)
		width: 62px
		height: 62px

.Image
	position: relative
	width: 100%
	border-radius: 10px
	overflow: hidden
	&::after
		content: ""
		display: block
		padding-top: calc(100% / var(--projectImage-aspectRatio, 1))

.Close
	display: none

.Name,
.Text,
.Year
	margin: 0

.Name
	+common.button
	font-size: 24px
	line-height: 32px
	font-weight: 500
	border-radius: 25px
	width: fit-content
	border: 1px solid transparent
	padding: 0 0.5rem
	margin: -0.1rem -0.6rem

.Position
	margin-bottom: auto
	@media (min-width: 768px)
		margin-bottom: 1rem

.Year,
.Text
	font-size: 18px
	line-height: 24px

.Text
	margin-top: 24px
	margin-bottom: auto
	a
		text-decoration: underline

.Description
	font-weight: 500

.Year
	color: #000
	opacity: 0.5
	@media (min-width: 768px)
		margin-top: 12px

.InfoBox
	display: none

.WhiteText
	color: #fff
