@use 'common'

.Image
	@media (min-width: 768px)
		margin: 2rem 0

	.Note
		display: flex
		justify-content: flex-end
		font-size: 0.8rem
		font-style: italic
		opacity: 0.5
